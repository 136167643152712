import { IconWithBorder } from "../../../IconWithBorder/IconWithBorder";

import { IoMdMail } from "react-icons/io";
import { BsFillTelephoneFill } from "react-icons/bs";
import { TiLocation } from "react-icons/ti";

import "./ContactInfo.scss";
import { FormattedMessage } from "react-intl";

export const ContactInfo = () => {
  return (
    <div className="contact-info">
      <span>
        <FormattedMessage
          id="footer.contact"
          defaultMessage="_footer-contact_"
        />
      </span>
      <div>
        <div className="contact-info__item">
          <IconWithBorder size="small" mode="dark">
            <BsFillTelephoneFill />
          </IconWithBorder>
          <a href="tel:+995322500700">(995 32) 2500 700</a>
        </div>
        <div className="contact-info__item">
          <IconWithBorder size="small" mode="dark">
            <TiLocation />
          </IconWithBorder>
          <a href="https://maps.app.goo.gl/dfBqPDAXATWVme7B6" target="_blank"> ქ. თბილისი, ვაჟა-ფშაველას გამზ. №27ბ </a>
        </div>
        <div className="contact-info__item">
          <IconWithBorder size="small" mode="dark">
            <IoMdMail />
          </IconWithBorder>
          <a href="mailto:info.clinic@aversi.ge">Info.clinic@aversi.ge</a>
        </div>
      </div>
    </div>
  );
};
